<template>
    <div class="main__container main__padding">
        <div class="row__start">
            <div class="backbutton pointer" @click.stop="goToHome">
                <ArrowLeftDefaultSvg />
            </div>
            <h2 class="pointer" @click.stop="goToHome">Suche</h2>
        </div>
        <div style="margin-top: 30px"></div>
        <h2 v-if="oneCompany" class="mt-0">{{ oneCompany.name }}</h2>
        <CompanyHeader v-if="oneCompany" />
        <div class="row__start company__row">
            <div class="row__start--flex1 image__row">
                <CompanyImages v-if="oneCompany" />
            </div>
            <div class="row__start--flex3 row__flex3--w43">
                <div class="appointmentBox">
                    <h1>Termin vereinbaren</h1>
                    <p
                        class="mt-15 mb-60"
                        v-if="oneCompany.hasWorkshops == true"
                    >
                        Um einen Termin mit uns zu buchen, stehen Dir an
                        unterschiedlichen Tagen und Zeiträume verschiedene
                        Ansprechpartner zur Verfügung. Du kannst wählen ob Du
                        per Telefon, Videomeeting oder Chat mit uns
                        kommunizieren möchtest. <br />Wir freuen uns auf Deine
                        Terminbuchung (15 Minuten pro Termin) und Deine
                        anschließende Kontaktaufnahme über die Daten, die Dir
                        per E-Mail nach der Buchung mitgeteilt werden.
                    </p>
                    <p
                        class="mt-15 mb-60"
                        v-if="oneCompany.hasWorkshops == false"
                    >
                        Bitte über unsere allgemeinen Kontaktdaten einen Termin
                        vereinbaren.
                    </p>
                    <div
                        class="absoluteBox absoluteBox--right"
                        v-if="oneCompany.hasWorkshops == true"
                    >
                        <a
                            class="button button--big"
                            :class="token ? '' : 'button--grey'"
                            @click.stop="openAppointmentModal({ id })"
                        >
                            <p class="text__white">Jetzt Termin buchen</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <CompanyContent />
        <CompanyAppointmentModal
            :company_id="id"
            :showModal="showAppointmentModal"
            @close="showAppointmentModal = false"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "Company",
    props: ["id"],
    data() {
        return {
            showAppointmentModal: false,
        };
    },
    methods: {
        ...mapActions(["getOneCompany", "openAppointmentBooking"]),
        goToHome() {
            this.$router.push({
                name: "Home",
            });
        },
        async openAppointmentModal({ id }) {
            let target = event.target;
            if (target.classList.contains("button")) {
                target = target.childNodes[0];
            }
            target.innerHTML = "<div class='loading'></div>";
            if (!this.token) {
                this.$router.push({
                    name: "Login",
                });
                return;
            }
            await this.openAppointmentBooking({ id });
            target.innerHTML = "Jetzt Termin buchen";
            this.showAppointmentModal = true;
        },
    },
    created() {
        this.getOneCompany({ id: this.id });
    },
    computed: {
        ...mapGetters(["oneCompany", "token"]),
    },
    mounted() {},
};
</script>
